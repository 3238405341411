module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-Q8QPBPSXN3"],"gtagConfig":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-hubspot/gatsby-browser.js'),
      options: {"plugins":[],"trackingCode":"9252988","productionOnly":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-apollo/gatsby-browser.js'),
      options: {"plugins":[],"uri":"https://cardb-production.driveninja.com/client_schema"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/logos/driveNinjaManifest.png","icon_options":{"purpose":"any maskable"},"name":"Drive Ninja","short_name":"Drive Ninja","start_url":"/","background_color":"#F69E1F","theme_color":"#F69E1F","display":"standalone","cache_busting_mode":"name","legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"5bb2e8108e98b727c475eeef80ee5a12"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
